import "./footer.css";
import { Link, useLocation } from "react-router-dom";

function Footer(props) {
    var profile = props.profile;
    const location = useLocation();
    const data = [
        {
            icon: "nav-gift",
            iconactive: "nav-gift-active",
            title: "Khuyến Mãi",
            to: "/notification"
        },
        {
            icon: "nav-assistant",
            iconactive: "nav-assistant-active",
            title: "Nạp Tiền",
            to: "/recharge"
        },
        {
            icon: "nav-home2",
            iconactive: "nav-home-active2",
            title: "Trang Chủ",
            to: "/"
        },
        {
            icon: "nav-agent",
            iconactive: "nav-agent-active",
            title: "Đại Lý",
            to: "/invite"
        },
        {
            icon: "nav-user",
            iconactive: "nav-user-active",
            title: "Tài Khoản",
            to: "/profile"
        }
    ];

    return (
        <>
            <div className="footer">
                {data.map((item, index) => (
                    <div className={location.pathname === item.to || location.pathname.substring(0, 9) === item.to ? "footer_item active" : "footer_item"} key={index}>
                        <Link style={{ textDecoration: "none" }} to={item.to}>
                            {index == 2 ? (
                                <>
                                    <div className="icon_footer">
                                        <div class="icon_footer_center">
                                            <img
                                                alt=""
                                                src={require(`../../static/${
                                                    location.pathname === item.to || location.pathname.substring(0, 9) === item.to ? item.iconactive : item.icon
                                                }.png`)}
                                            />
                                        </div>
                                    </div>
                                    <div className="title_footer">{item.title}</div>
                                </>
                            ) : (
                                <>
                                    <div className="icon_footer">
                                        <img
                                            alt=""
                                            src={require(`../../static/${
                                                location.pathname === item.to || location.pathname.substring(0, 9) === item.to ? item.iconactive : item.icon
                                            }.png`)}
                                        />
                                    </div>
                                    <div className="title_footer">{item.title}</div>
                                </>
                            )}
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
}
export default Footer;
